export const defaultSettings = {
    disks: 3,
    stacks: 3,
    startStack: 1,
    anyEndStack: true,
    endStack: 3,
    showPegs: true,
    diskNumbers: false,
    illegalMoves: false,
    blindfold: false,
    keyBind21: 's',
    keyBind12: 'd',
    keyBind13: 'f',
    keyBind31: 'j',
    keyBind32: 'k',
    keyBind23: 'l',
    keyReset: 'r',
    keyUndo: 'z',
};
